
import Header from "@/components/home-page/Header.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import CustomButton from "@/components/CustomButton.vue";
import AvatarImage from "@/components/AvatarImage.vue";
import Server from "@/interfaces/Server";
import { PopoutsModule } from "@/store/modules/popouts";
import {
  getServerInfoByCode,
  joinServerByCode,
} from "@/services/serverService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "InviteServer",
  components: {
    Header,
    LoadingScreen,
    AvatarImage,
    CustomButton,
  },
  data() {
    return {
      server: null as Server | null,
      error: false,
      loggedIn: localStorage["hauthid"] || false,
      requestSent: false,
    };
  },
  computed: {
    inviteID(): any {
      return this.$route.params.inviteid;
    },
  },
  mounted() {
    getServerInfoByCode(this.inviteID)
      .then((data: any) => {
        this.server = data;
      })
      .catch(() => (this.error = true));
  },
  methods: {
    joinServer() {
      PopoutsModule.ShowPopout({
        id: "captcha-popout",
        component: "CaptchaPopout",
        data: {
          callback: this.captchaVerified,
        },
      });
    },
    captchaVerified(token: string) {
      if (this.requestSent) return;
      this.requestSent = true;
      joinServerByCode(this.inviteID, { token })
        .then(() => {
          location.href = `/app/servers/${this.server?.server_id}/${this.server?.default_channel_id}`;
        })
        .catch(async (err) => {
          PopoutsModule.ShowPopout({
            id: "error",
            component: "generic-popout",
            data: {
              title: "Error Joining Server",
              description: !err.response
                ? this.$t("could-not-connect-to-server")
                : (await err.response.json()).message,
            },
          });
        })
        .finally(() => (this.requestSent = false));
    },
    loginButton() {
      this.$router.push("/login?redirect=" + encodeURIComponent(location.href));
    },
  },
});
